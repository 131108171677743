<template>
  <div>
		<v-data-table
			:headers="headers"
			:items="acknowledgementreceipt.data"
			class="elevation-1"
			dense
			:search="search"
			:items-per-page="1000"
			hide-default-footer
		>
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Acknowledgement Receipt Maintenance</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field> 
		<vue-excel-xlsx
			:data="acknowledgementreceipt.data"
			:columns="columnHeaders"
			:file-name="module_name"
			>
			<v-btn icon alt class="mt-2" :color="acknowledgementreceipt.data.length=== 0 ? 'gray' : 'primary'" :disabled="acknowledgementreceipt.data.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
		</vue-excel-xlsx>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
			<!-- <v-icon
        color="primary"
        class="mr-2"
        small
        @click="editItem(item)"
      >
        edit
      </v-icon> -->
	<v-icon
        color="grey"
        class="mr-2"
        small
		v-if="role_access < 4"
      >
        mdi-delete-off
      </v-icon>
      <v-icon
        small
		v-else
        color="red"
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
		<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(acknowledgementreceipt.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(acknowledgementreceipt.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{acknowledgementreceipt.current_page}} ( {{acknowledgementreceipt.from}} - {{acknowledgementreceipt.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!acknowledgementreceipt.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!acknowledgementreceipt.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
  </v-data-table>
  <create-acknowledgement-receipt v-if="role_access > 1"></create-acknowledgement-receipt>
  </div>
</template>

<script>
import createAcknowledgementReceipt from './createAcknowledgementReceipt.vue';
import { mapGetters } from 'vuex';


export default {
	components: {
		'create-acknowledgement-receipt': createAcknowledgementReceipt
	},
	data(){
		return{
			search: '',
			module_name: 'Acknowledgement Receipt',
			role_access: 1,
		};
	},
	computed: {
		...mapGetters({
			acknowledgementreceipt: 'acknowledgementreceipt/acknowledgementreceipt',
			headers: 'acknowledgementreceipt/headers',
			columnHeaders: 'acknowledgementreceipt/columnHeaders',
			editedItem: 'acknowledgementreceipt/editedItem',
			defaultItem: 'acknowledgementreceipt/defaultItem',
			dialog: 'acknowledgementreceipt/dialog',
			editedIndex: 'acknowledgementreceipt/editedIndex',
			valid: 'acknowledgementreceipt/valid',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('acknowledgementreceipt/getAcknowledgementReceipt');
		
		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		download () {
			//Download
		},
		editItem (item) {
			this.$store.dispatch('acknowledgementreceipt/setEditedIndex',this.acknowledgementreceipt.data.indexOf(item));
			this.$store.dispatch('acknowledgementreceipt/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('acknowledgementreceipt/setDialog',true);
			this.$store.dispatch('acknowledgementreceipt/setValid',true);
			this.$store.dispatch('acknowledgementreceipt/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$store.dispatch('acknowledgementreceipt/setEditedIndex',this.acknowledgementreceipt.data.indexOf(item));
			this.$swal.fire({
				title: 'Delete Acknowledgement Receipt?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('acknowledgementreceipt/deleteAcknowledgementReceipt',item);
				}
			});
		},
		changeNextData() {
			var nextPage = this.acknowledgementreceipt.current_page + 1;
			this.$store.dispatch('acknowledgementreceipt/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.acknowledgementreceipt.current_page - 1;
			this.$store.dispatch('acknowledgementreceipt/getDataPages', prevPage);
		}
	},
};
</script>

