<template>
     <v-dialog v-model="dialog" persistent max-width="60%" scrollable>
      <template v-slot:activator="{ on }">
          <app-add-button v-bind:module="'acknowledgementreceipt'"></app-add-button>
        </template>
          <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
          <v-card title>
                <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{formTitle}}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
           <v-card-text>
             
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                   <v-text-field 
                        v-model="editedItem.series_from"
                        :counter="15"
                        label="Series From"
                        required
                        :rules="series_rule"
                        maxlength=15
                    ></v-text-field>
                  </v-flex>
                     <v-flex xs12 sm6 md6>
                     <v-text-field 
                        v-model="editedItem.series_to"
                        :counter="15"
                        :rules="series_rule"
                        label="Series To"
                        required
                        maxlength=15
                    ></v-text-field>
                    </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue" dark @click="save">Save</v-btn>
              <v-btn color="red" text @click="close">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		search: null,
		isvalid: true,
		series_rule: [
			v => !!v || 'Required'
		]
	}),

	computed: {
		...mapGetters({
			voucherseries: 'acknowledgementreceipt/acknowledgementreceipt',
			headers: 'acknowledgementreceipt/headers',
			editedItem: 'acknowledgementreceipt/editedItem',
			defaultItem: 'acknowledgementreceipt/defaultItem',
			dialog: 'acknowledgementreceipt/dialog',
			editedIndex: 'acknowledgementreceipt/editedIndex',
			formTitle: 'acknowledgementreceipt/formTitle',
			valid: 'acknowledgementreceipt/valid',
			isLoading: 'app/isLoading',
			currUser: 'auth/currUser'
		}),
	}, 
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('acknowledgementreceipt/setValid',true);
				this.$store.dispatch('acknowledgementreceipt/setDialog',false);
				this.$store.dispatch('acknowledgementreceipt/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save () {
			this.$store.dispatch('acknowledgementreceipt/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if(this.valid){
				if(this.editedItem.series_from >= this.editedItem.series_to){
					this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Series to must be greater than series from.','messagetype':'error'},{ root: true });
				}else{
					this.editedItem.edit_by = this.currUser.id;
					if (this.editedIndex > -1) {
						this.$store.dispatch('acknowledgementreceipt/updateAcknowledgementReceipt',this.editedItem);
					} else {
						this.$store.dispatch('acknowledgementreceipt/saveAcknowledgementReceipt',this.editedItem);
					}
					this.close();
				}
			}
		},
      
	}
};
</script>
